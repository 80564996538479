/* ../../css/HTML.css */

.contact-form {
    max-width: 800px; /* Adjust width as needed */
    margin: auto;
    background-color: #000;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
}

.contact-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.contact-form label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="tel"],
.contact-form textarea,
.contact-form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
}

.contact-form textarea {
    height: 150px;
}

.contact-form button {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.contact-form button:hover {
    background-color: #45a049;
}

